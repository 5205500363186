/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body {
  font-family: "Poppins", sans-serif;
}
.MuiTableCell-root {
  text-align: start !important;
}

.MuiTableCell-root .MuiButton-label {
  word-break: initial !important;
}
.print-hide {
  display: inherit;
}

.print-only {
  display: none;
}

p,
label,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  word-break: initial;
}

/* span:not(button > span) {
  word-break: break-word;
} */

span:not(:has(*)):only-child {
  word-break: initial;
}

div:not(:has(*)):only-child,
div {
  word-break: initial;
  word-wrap: break-word;
}

@media print {
  .print-hide {
    display: none !important;
  }

  .print-only {
    display: block;
  }
}
.children-allergies
  .MuiTable-root
  .MuiTableHead-root
  .MuiTableRow-root
  th[data-colindex="0"] {
  width: 50px;
}

.DraftEditor-root {
  top: -15px;
  position: relative;
}
