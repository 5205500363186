#on-boarding .primary-color {
  color: #304fff;
}

#on-boarding.page-wrapper {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#on-boarding .content {
  flex-grow: 1;
}

#on-boarding .top {
  background: #304fff;
  color: white;
}

#on-boarding .center {
  display: flex;
  place-content: center;
  place-items: center;
}

#on-boarding .top-heading {
  color: #ffffff;
  font-size: 45px;

  margin-top: 6rem;
}

#on-boarding .top-heading-subtitle {
  color: #ffffff;
  font-size: 19px;
  font-weight: normal;
  line-height: 2.1em;
  letter-spacing: 1px;
}

#on-boarding .logo {
  height: 50px;
  width: 220px;
}

#on-boarding .forms-section {
  background: #ffffff;
}

#on-boarding .form-description {
  font-size: 23px;
  font-weight: 700;
}

#on-boarding .MuiButton-root.MuiButton-containedPrimary,
#on-boarding .MuiButton-root.MuiButton-containedPrimary:focus,
#on-boarding .MuiButton-root.MuiButton-containedPrimary:hover {
  background-color: #304fff;
}

#on-boarding .MuiButton-root.MuiButton-containedPrimary.Mui-disabled {
  background-color: #6b7ee8;
  color: white;
}

#on-boarding .MuiInputBase-root .MuiInputAdornment-root .MuiIconButton-root {
  color: #304fff;
}

#on-boarding .MuiInputBase-root.MuiButton-contained.Mui-disabled {
  color: white;
  box-shadow: none;
  background-color: #8392e7 !important;
}

#on-boarding .phone-border {
  border-color: #304fff !important;
}

#on-boarding .phone-border .PhoneInputInput {
  color: #304fff !important;
}

#on-boarding label {
  color: #304fff;
}

#on-boarding .MuiButton-outlined.Mui-disabled {
  background-color: #a49e9e;
}

#on-boarding .MuiButtonBase-root.MuiButton-outlinedPrimary,
#on-boarding .MuiButtonBase-root.MuiButton-outlinedPrimary:hover,
#on-boarding .MuiButtonBase-root.MuiButton-outlinedPrimary:focus {
  background-color: #565454;
  color: #ffffff;
}

#on-boarding .MuiCheckbox-root {
  color: #304fff !important;
}

#on-boarding .top-icons .MuiButtonBase-root.MuiIconButton-root,
#on-boarding .top-icons .material-icons.MuiIcon-root {
  color: #304fff !important;
}

#on-boarding .top-icons .MuiButtonBase-root.MuiIconButton-root:hover {
  background-color: #b7bbd1;
}

#on-boarding .phone_label::after {
  content: " *";
  color: red;
}
#on-boarding .error-text {
  color: red;
}

#on-boarding .a-tag {
  color: blue;
  text-decoration: underline;
}
#on-boarding .flex.w-full > div:first-child {
  width: 100%;
}

@media (max-width: 767px) {
  #on-boarding .text-left {
    text-align: right;
  }

  #on-boarding .top {
    padding-bottom: 0 !important;
  }
  #on-boarding .top-heading {
    margin-top: 1.5rem;
  }
  #on-boarding .logo {
    height: 40px;
    width: 160px;
  }

  .otp-label {
    width: 90% !important;
  }
}

.otp-label {
  width: 55%;
}
