.proposal-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

#mobile-proposal .flyer {
  width: 100%;
}

#mobile-proposal .phone-border,
#mobile-proposal .phone-border .PhoneInputInput {
  border-color: #304fff !important;
}

#mobile-proposal .phone_label::after {
  content: " *";
  color: red;
}
#mobile-proposal .phone_col {
  /* align-self: end; */
  margin-top: 19px;
}
.phone_label {
  color: #304fff;
}

.church-logo {
  width: 3.5rem;
}
.phone_input .PhoneInputInput {
  width: 100%;
  color: #304fff;
}

.copyright {
  position: absolute;
  bottom: 5px;
}

.primary-color {
  color: #304fff;
}

.right-col {
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
}

#mobile-proposal .a-tag {
  color: blue;
  text-decoration: underline;
}

.thank-you {
  text-align: center;
  margin: 50px auto;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* background-color: #f5f5f5; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thank-you h2 {
  color: #304fff;
  font-size: 4em;
  margin-bottom: 10px;
}

#mobile-proposal .MuiFormLabel-root .MuiFormLabel-asterisk {
  color: red !important;
}
