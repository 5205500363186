.PhoneInputCountry {
  display: inline-block;
}
.PhoneInputCountryIcon {
  top: 1px;
  position: relative;
}
.left-span {
  top: 58%;
}
.PhoneInput {
  border: solid 1px #555b70;
  padding: 11px 0;
  background: white;
  border-radius: 5px;
}
.PhoneInput .PhoneInputInput {
  background: transparent;
  border: 0;
  /* color: white; */
}
.PhoneInputInput:focus {
  border: 0;
  outline: 0;
}
.PhoneInputCountrySelectArrow {
  display: none;
}
.mobile input {
  border: none;
  background: none;
  margin-right: 10px;
  /* color:  #3F4254;; */
}
.mobile input::placeholder {
  color: #bfc0c4;
}
.mobile input:focus {
  outline: none;
}
.PhoneInputInput input#loginMobile.PhoneInputInput {
  background: red !important;
}
input:-internal-autofill-selected {
  background: green;
}
.check {
  display: inline-block;
  transform: rotate(45deg);
  height: 12px;
  width: 8px;
  border-bottom: 3px solid #78b13f;
  border-right: 3px solid #78b13f;
  position: absolute;
  top: 12px;
  right: 20px;
}

.x {
  color: red;
  font-style: italic;
  font-family: fantasy;
  position: absolute;
  top: 12px;
  right: 20px;
  font-weight: bold;
  /* transform: rotate(45deg); */
}
.errorMessage {
  position: relative;
  bottom: 14px;
}
